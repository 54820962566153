import { SuspensionType } from "src/app/core/interfaces/suspension-type.interface";
import { DebitType } from "src/app/features/admin/debit-types/interfaces/debit-type.interface";

export class LoadPrefillData {
  static readonly type = "[PrefillData] Load Data";
}

export class LoadPortsAsOptions {
  static readonly type = "[PrefillData] Load Ports as Options";
}

export class LoadSuspensionTypesAsOptions {
  static readonly type = "[PrefillData] Load Suspension Types as Options";
  constructor(
    public suspensionTypes: Array<SuspensionType>
  ) {}
}
export class LoadDebitTypesAsOptions {
  static readonly type = "[PrefillData] Load Debit Types as Options";
  constructor(
    public debitTypes: Array<DebitType>
  ) {}
}

export class SetFilter {
  static readonly type = "[PrefillData] Set Filter";
  constructor(
    public filter: | "filteredPorts" | "filteredTerminals" | "filteredStates" | "filteredEmployers" | any,
    public items: any
  ) {}
}

export class AddFilter {
  static readonly type = "[PrefillData] Add Filter";
  constructor(
    public filter: "filteredPorts" | "filteredTerminals" | "filteredStates" | "filteredEmployers",
    public item: any
  ) {}
}

export class RemoveFilter {
  static readonly type = "[PrefillData] Remove Filter";
  constructor(
    public filter: "filteredPorts" | "filteredTerminals" | "filteredStates" | "filteredEmployers",
    public item: any
  ) {}
}

export class SetStatesAsOptions {
  static readonly type = "[PrefillData] Load States as Options";
  constructor(public states?: any) {}
}

export class LoadTerminalsAsOptions {
  static readonly type = "[PrefillData] Load Terminals as Options";
}

export class LoadUnionsAsOptions {
  static readonly type = "[PrefillData] Load Unions as Options";
}

export class GetTerminalsOptionsByPort {
  static readonly type = "[PrefillData] Get Terminals Options by Port";
  constructor(public portId: any) {}
}

export class LoadCitiesAsOptions {
  static readonly type = "[PrefillData] Load Cities as Options";
  constructor(public cities?: any) {}
}

export class AddCityAsOption {
  static readonly type = "[PrefillData] Add City as Option";
  constructor(public city?: any) {}
}

export class AddStateAsOption {
  static readonly type = "[PrefillData] Add State as Option";
  constructor(public item?: any) {}
}

export class LoadEmployersAsOptions {
  static readonly type = "[PrefillData] Load Employers as Options";
}

export class LoadVesselsAsOptions {
  static readonly type = "[PrefillData] Load Vessels as Options";
}

export class LoadAvailabilityTypesAsOptions {
  static readonly type = "[PrefillData] Load AvailabilityTypes as Options";
}

export class LoadTenantId {
  static readonly type = "[PrefillData] Load Tenant Id";
  constructor(public name: any) {}
}

export class LoadUseMuster {
  static readonly type = "[PrefillData] Load Use Muster";
  constructor() {}
}

export class LoadDeptCodesAsOptions {
  static readonly type = "[PrefillData] Load DeptCodes as Options";
}

export class LoadBerthsAsOptions {
  static readonly type = "[PrefillData] Load Berths as Options";
}

export class LoadJobTypesAsOptions {
  static readonly type = "[PrefillData] Load JobTypes as options"
}

export class LoadUsersAsOptions {
  static readonly type = "[PrefillData] Load Users as Options";
}